import Engine, { RPMAvatar, MyLoaders, RPMAvatarHull } from "@ravespaceio/rave-engine"
import * as THREE from "three"
import { getEngine } from "~/space/engine"
import { getSpace } from "~/space/space"
import { AvatarConfigWithoutPreview, transformAvatarConfig } from "../utils/rpm"




type NPCConfig = AvatarConfigWithoutPreview & {
	name: string,
	position: THREE.Vector3,
	rotation: number,
	animation: string,
	avatar?: RPMAvatar
}

export function createNPC(npcConfig: NPCConfig, manageLoopAndScene: boolean = false): RPMAvatarHull {
	const space = getSpace()
	const engine = getEngine()
	const config = transformAvatarConfig(npcConfig)

	let avatarHull: RPMAvatarHull
	if (!space.loader.isLoaded && !space.loader.isLoading) {
		// preloaded
		space.loader.addFile(config.animationsPath)
		space.loader.addFile(config.modelPath)
		const gltfProm = space.loader.getLoadingPromise(config.modelPath)
		const aniProm = space.loader.getLoadingPromise(config.animationsPath)
		avatarHull = RPMAvatar.createHullFromPromise(gltfProm, aniProm, npcConfig.gender == "feminine")
	} else {
		// dynamic loaded
		avatarHull = RPMAvatar.createHullFromConfig(config, npcConfig.gender == "feminine")
	}

	avatarHull.loadingPromise.then((avatar) => {
		avatar.scale.set(npcConfig.scale, npcConfig.scale, npcConfig.scale)
		avatar.triggerAction(npcConfig.animation, 0, true)
		if (manageLoopAndScene) {
			engine.scene.add(avatarHull)
			engine.loop.register((dt) => {
				if (avatar.parent && avatar.parent.visible) {
					avatar.updateMixerOnly(dt)
				}
			})
		}
	})
	avatarHull.position.copy(npcConfig.position)
	avatarHull.rotation.y = npcConfig.rotation
	avatarHull.name = npcConfig.name
	return avatarHull
}

