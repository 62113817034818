<template>
	<div v-show="showPointer" id="worldPointer" />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { getSpace } from '~/space/space'
import { useSpaceStore } from '~/store/space';

const showPointer = storeToRefs(useSpaceStore()).showPointer

</script>

<style scoped lang="scss">
#worldPointer {
	position: fixed;
	left: 50%;
	top: 50%;
	z-index: 10;
	background: #000000;
	width: 6px;
	height: 6px;
	border: 1px solid white;
	border-radius: 100%;
	z-index: 1000;
}
</style>
