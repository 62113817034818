<template>
	<div class="close">
		<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M16.207 13.549L10.983 8.328L16.203 3.104C16.805 2.504 16.805 1.539 16.205 0.937L15.72 0.451C15.435 0.159 15.045 0 14.635 0C14.633 0 14.633 0 14.633 0C14.223 0 13.838 0.161 13.55 0.45L8.328 5.676L3.104 0.456C2.505 -0.144 1.541 -0.147 0.939 0.453L0.453 0.934C0.16 1.221 0 1.611 0 2.02C0 2.431 0.161 2.818 0.45 3.106L5.676 8.328L0.455 13.552C-0.147 14.152 -0.147 15.117 0.453 15.721L0.938 16.206C1.225 16.498 1.614 16.657 2.024 16.657C2.432 16.657 2.822 16.494 3.109 16.207L8.33 10.982L13.552 16.201C13.848 16.5 14.242 16.651 14.637 16.651C15.028 16.651 15.42 16.502 15.719 16.204L16.204 15.72C16.498 15.435 16.657 15.045 16.657 14.635C16.659 14.225 16.498 13.838 16.209 13.549H16.207Z" fill="black" />
		</svg>
	</div>
</template>


<style scoped lang="scss">
@import "assets/css/variables";

.close {
	background: $main;
	border: $stroke solid $dark;
	box-sizing: border-box;
	box-shadow: -1px 4px 9px rgba(7, 6, 17, 0.15);
	border-radius: $radius;
	position: absolute;
	right: 10px;
	top: 10px;
	@extend .flex-center;
	width: 40px;
	height: 40px;
	cursor: pointer;

	svg {
		transition: ease 0.3s;

		* {
			fill: $dark;
		}
	}

	&:hover {
		svg {
			transform: rotate(90deg);
		}
	}
}
</style>
