<template>
	<div>
		<client-only>
			<SpaceMain />
		</client-only>
	</div>
</template>

<script setup lang="ts">

</script>

<style></style>
