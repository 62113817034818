import Engine, { RavespaceEvent } from "@ravespaceio/rave-engine"
import GameLoop from "@ravespaceio/rave-engine/build/engine/src/engine/core/GameLoop"


/**
 * does not consider visibilitychange, focus, requesanimationFrame, renderer pause yet
 */
export default class LowFpsDetecter {


	private _lowFpsEvent = new RavespaceEvent<"lowfps">()
	public lowFpsEvent = this._lowFpsEvent.expose()

	constructor(engine: Engine) {

		var lowfps = false
		var loops = 0
		var fps
		var tid: any

		const treshold = 20

		// Check if fps < treshold
		// if its less than treshold for 3 loops, give hint
		// if one loop has more than treshold, start again

		const loop = () => {
			tid = setTimeout(() => {
				if (document.hidden) return;
				fps = engine.loop.getFPS()
				if (loops > 2) {
					this._lowFpsEvent.trigger("lowfps")
				} else if (fps < treshold) {
					lowfps = true
					loops++
					loop()
				} else {
					lowfps = false
					loops = 0
					loop()
				}
			}, 8000)
		}
		loop()

		// clear
		engine.onDestroy.on(() => {
			clearTimeout(tid)
		})
	}
}
